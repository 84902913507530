<template>
  <nav class="py-8">
    <router-link to="/" class="font-bold text-gray-800">Home</router-link> |
    <router-link to="/about" class="font-bold text-gray-800">About</router-link>
  </nav>
</template>

<script>
export default {
  name: "MainNavigation",
};
</script>

<template>
  <div
    id="app"
    class="min-h-screen text-gray-800 relative bg-neutral-900 shadow-inner-lg"
  >
    <loading-spinner
      v-if="isLoading"
      :stepLabel="steps[currentStep].label"
      :progress="steps[currentStep].progress"
    />

    <main-navigation v-else />
    <main-content v-if="!isLoading" />
  </div>
</template>

<script>
import LoadingSpinner from "./components/LoadingSpinner.vue";
import MainNavigation from "./components/MainNavigation.vue";
import MainContent from "./components/MainContent.vue";

export default {
  components: {
    LoadingSpinner,
    MainNavigation,
    MainContent,
  },
  data() {
    return {
      isLoading: true,
      steps: [
        { label: "Сосем писю", progress: 33 },
        { label: "Сосем дальше...", progress: 66 },
        { label: "Почти что мехди...", progress: 100 },
      ],
      currentStep: 0,
    };
  },
  mounted() {
    this.loadNextStep(); // Начинаем загрузку
  },
  methods: {
    loadNextStep() {
      if (this.currentStep < this.steps.length - 1) {
        setTimeout(() => {
          this.currentStep++;
          this.loadNextStep(); // Загружаем следующий шаг
        }, 2000); // Каждую секунду
      } else {
        // Завершаем загрузку
        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      }
    },
  },
};
</script>

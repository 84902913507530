<template>
  <div
    class="preloader flex flex-col items-center justify-center w-full h-full absolute"
  >
    <div class="logo flex flex-col items-center">
      <span
        class="text-fsize leading-none italic font-bold"
        :style="{
          background: 'linear-gradient(180deg, #4A90E2 0%, #0056A6 100%)',
          '-webkit-background-clip': 'text',
          '-webkit-text-fill-color': 'transparent',
        }"
      >
        CLOUD
        <br />
        УМЕНЯ
        <br />
        СЕМЬЯ
        <br />
        НЕСАЖАЙТЕ
      </span>
      <span
        class="text-fsize italic font-bold"
        :style="{
          background: 'linear-gradient(180deg, #4A90E2 0%, #0056A6 100%)',
          '-webkit-background-clip': 'text',
          '-webkit-text-fill-color': 'transparent',
        }"
      >
        PHARM
      </span>
    </div>

    <div
      class="leading-normal loader-step absolute bottom-6 text-white font-semibold"
    >
      {{ stepLabel }}
    </div>

    <div class="absolute bottom-0 mb-2.5 w-4/5 rounded h-2 bg-gray-50">
      <div
        class="bg-sky-800 h-full rounded"
        :style="{ width: progress + '%' }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
  props: {
    stepLabel: {
      type: String,
      required: true,
    },
    progress: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.logo {
  font-family: "TT Hoves Pro", sans-serif; /* добавьте запасной шрифт для случаев, если основной не загрузится */
}

.loader-step {
  font-family: "Montserrat", sans-serif;
}
</style>
